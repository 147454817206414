<template>
  <div
    v-if="isDevStore || !isProd"
    class="fixed bottom-0 z-10 w-full p-2 text-center opacity-75"
    :class="isDevStore ? 'bg-yellow-100 text-black' : 'bg-red-700 text-white'"
  >
    <div>
      {{ isDevStore ? 'Playground mode 🦖' : '❕ Bigcommerce PROD ❕' }}
    </div>
  </div>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const { isProd, isDevStore } = runtimeConfig.public;
</script>

<style lang="scss" scoped></style>
